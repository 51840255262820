import {
  HTTP_INTERCEPTORS,
  provideHttpClient,
  withInterceptorsFromDi,
} from '@angular/common/http';
import { NgModule, APP_INITIALIZER, LOCALE_ID } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AuthInterceptor } from '@interceptors/auth.interceptor';
import { WindowWithUser } from '@longnecktech/splash-commons-fe';
import { SessionService } from '@services/session.service';
import { registerLocaleData, CurrencyPipe } from '@angular/common';
import { TranslationService } from '@services/translation.service';
import { getTokenParams } from '@shared/utils/get-token-params';
import localeDe from '@angular/common/locales/de';
import localeHr from '@angular/common/locales/hr';
import localeEn from '@angular/common/locales/en';
import localeSe from '@angular/common/locales/se';
import localePt from '@angular/common/locales/pt';
import localeFr from '@angular/common/locales/fr';
import localeSv from '@angular/common/locales/sv';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

registerLocaleData(localeDe, 'de');
registerLocaleData(localeHr, 'hr');
registerLocaleData(localeEn, 'en');
registerLocaleData(localeSe, 'se');
registerLocaleData(localePt, 'pt');
registerLocaleData(localeFr, 'fr');
registerLocaleData(localeSv, 'sv');

export function localeFactory() {
  const lang = (window as WindowWithUser).user.language || 'en';
  return TranslationService.supportedLanguages.includes(lang) ? lang : 'en';
}

export function startupServiceFactory(sessionService: SessionService) {
  return () => {
    const params = getTokenParams();
    sessionService.thirdPartyToken = params.token;
    sessionService.instance = params.instance;
    sessionService.gameUuid = params.gameUuid;
    sessionService.isFromHub = params.isFromHub;
    sessionService.setUser((window as WindowWithUser).user);
  };
}

@NgModule({
  declarations: [AppComponent],
  bootstrap: [AppComponent],
  imports: [BrowserModule, AppRoutingModule, BrowserAnimationsModule],
  providers: [
    CurrencyPipe,
    {
      provide: APP_INITIALIZER,
      useFactory: startupServiceFactory,
      deps: [SessionService],
      multi: true,
    },
    {
      provide: LOCALE_ID,
      useFactory: localeFactory,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true,
    },
    provideHttpClient(withInterceptorsFromDi()),
  ],
})
export class AppModule {}
