import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { loggedInAuthGuard } from '@guards/logged-in-auth.guard';

const routes: Routes = [
  {
    path: '',
    children: [
      {
        path: 'm',
        loadChildren: () =>
          import('./features/mobile/mobile.module').then((m) => m.MobileModule),
      },
      {
        path: 'd',
        loadChildren: () =>
          import('./features/desktop/desktop.module').then(
            (m) => m.DesktopModule,
          ),
      },
    ],
    canActivate: [loggedInAuthGuard],
  },
  {
    path: 'error',
    loadChildren: () =>
      import('./features/error/error.module').then((m) => m.ErrorModule),
  },
  {
    path: '**',
    redirectTo: 'home',
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
