import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@environment';
import { ConfigValue } from '@longnecktech/splash-commons-fe';
import { BackgroundAnimationType } from '@longnecktech/splash-commons-fe/dist/types/background-animation';
import { SessionService } from '@services/session.service';
import { Game } from '@shared/types/game';
import { Theme } from '@shared/types/theme';
import { take, tap } from 'rxjs';
import { SpinService } from './spin.service';

@Injectable({
  providedIn: 'root',
})
export class GameService {
  constructor(
    private http: HttpClient,
    private session: SessionService,
    public readonly spinService: SpinService,
  ) {}

  fetchGame() {
    return this.http
      .get<Game>(
        `${environment.backendUrl}/api/wheel/game?${this.getQueryParams()}`,
      )
      .pipe(
        tap((data: Game) => {
          this.session.setCurrentGame(data);
          this.injectCSSVariablesIntoDOM(data.theme);
          this.spinService
            .fetchSpinInfo(data.uuid)
            .pipe(take(1))
            .subscribe({
              next: (nextSpinInfo) => {
                if (nextSpinInfo.nextSpinInSeconds > 0) {
                  this.spinService.updateShowNextSpinInfo(true);
                }
              },
            });
        }),
      );
  }

  private injectCSSVariablesIntoDOM(theme: Theme) {
    const styleEl = document.createElement('style');

    const fonts = `
      @font-face {
        font-family: PrimaryFont;
        src: url('${
          theme.primaryFontRegularAsset?.url
            ? theme.primaryFontRegularAsset.url
            : '/assets/fonts/Roboto_Condensed/RobotoCondensed-Regular.ttf'
        }') format('truetype');
      }

      @font-face {
        font-family: PrimaryFont;
        src: url('${
          theme.primaryFontBoldAsset?.url
            ? theme.primaryFontBoldAsset.url
            : '/assets/fonts/Roboto_Condensed/RobotoCondensed-Bold.ttf'
        }') format('truetype');
        font-weight: 700;
      }

      @font-face {
        font-family: PrimaryFont;
        src: url('${
          theme.primaryFontMediumAsset?.url
            ? theme.primaryFontMediumAsset.url
            : '/assets/fonts/Roboto_Condensed/RobotoCondensed-Medium.ttf'
        }') format('truetype');
        font-weight: 500;
      }

      @font-face {
        font-family: Headlines;
        src: url('${
          theme.jackpotFontAsset?.url
            ? theme.jackpotFontAsset.url
            : '/assets/fonts/Roboto_Condensed/RobotoCondensed-Regular.ttf'
        }') format('truetype');
      }
    `;
    const materialVariables = this.getOverwrittenAngularMaterialsVariables(
      theme.cssVariables,
    );
    const animationVariables = this.getAnimationVariables(
      theme.backgroundAnimation,
    );
    styleEl.textContent = `:root {
      ${theme.cssVariables
        .filter((v) => v.key.includes('--') && v.value)
        .map((v: ConfigValue) => {
          const originalValue = v.value;
          let rgbValue;

          if (originalValue.startsWith('rgba')) {
            const match = originalValue.match(/\d+/g);
            if (match) {
              rgbValue = `${match[0]}, ${match[1]}, ${match[2]}`;
            }
          } else if (originalValue.startsWith('rgb')) {
            const match = originalValue.match(/\d+/g);
            if (match) {
              rgbValue = match.join(', ');
            }
          } else if (originalValue.startsWith('#')) {
            rgbValue = this.hexToRgb(originalValue);
          }

          return `
          ${v.key}: ${originalValue};
          ${v.key}-rgb: ${rgbValue};
        `;
        })
        .join('\n')}
        --corner-radius-cta: ${theme.cornerRadiusCta !== null && theme.cornerRadiusCta !== undefined ? theme.cornerRadiusCta : 28}px;
        ${materialVariables}
        ${animationVariables}
    }
    ${fonts}
    `;
    document.head.appendChild(styleEl);
  }

  private getOverwrittenAngularMaterialsVariables(
    themeVariables: ConfigValue[],
  ): string {
    const baseColor = themeVariables.find((v) => v.key === '--color-base-1');
    const base25Color = themeVariables.find((v) => v.key === '--color-base-2');
    const textColor = themeVariables.find(
      (v) => v.key === '--color-neutral-100',
    );

    return `
    --mat-expansion-container-background-color: ${baseColor?.value};
    --mat-expansion-container-text-color: ${textColor?.value};
    --mdc-dialog-container-shape: 0;
    --mdc-dialog-container-color: ${base25Color?.value};
    `;
  }

  private getAnimationVariables(
    bgAnimationType: BackgroundAnimationType | null,
  ): string {
    if (!bgAnimationType) return '';
    if (bgAnimationType === BackgroundAnimationType.SNOW) {
      return '--particle-color: #ffffff';
    }
    if (bgAnimationType === BackgroundAnimationType.SPARKLE) {
      return '--particle-color: #f4b300';
    }
    return '';
  }

  private hexToRgb(hex: string) {
    const bigint = parseInt(hex.slice(1), 16);
    const r = (bigint >> 16) & 255;
    const g = (bigint >> 8) & 255;
    const b = bigint & 255;
    return `${r}, ${g}, ${b}`;
  }

  private getQueryParams(): string {
    const params: { instance?: string; gameUuid?: string } = {};
    if (this.session.instance) params['instance'] = this.session.instance;
    if (this.session.gameUuid) params['gameUuid'] = this.session.gameUuid;
    return new URLSearchParams(params).toString();
  }
}
