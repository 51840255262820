/**
 * Converts a color string to RGB format
 * Color in any supported format (hex, rgb, rgba)
 */
export function convertToRGB(color: string): string {
  if (color.startsWith('linear-gradient')) {
    const colorStopsRegex =
      /linear-gradient\(\s*(?:to\s+\w+|[\d.]+deg)?\s*,\s*([^,]+)(?:\s+\d+%)?(?:\s*,\s*[^,]+(?:\s+\d+%)?)*\s*\)/i;
    const match = color.match(colorStopsRegex);
    if (match && match[1]) {
      return convertToRGB(match[1].trim());
    }
  }

  if (color.startsWith('rgba')) {
    const match = color.match(/\d+/g);
    if (match) {
      return `${match[0]}, ${match[1]}, ${match[2]}`;
    }
  }
  if (color.startsWith('rgb')) {
    const match = color.match(/\d+/g);
    if (match) {
      return match.join(', ');
    }
  }
  if (color.startsWith('#')) {
    return hexToRGB(color);
  }
  return '0, 0, 0';
}

/**
 * Converts a hex color string to RGB format
 */
function hexToRGB(hex: string) {
  const bigint = parseInt(hex.slice(1), 16);
  const r = (bigint >> 16) & 255;
  const g = (bigint >> 8) & 255;
  const b = bigint & 255;
  return `${r}, ${g}, ${b}`;
}
